import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import PropTypes from 'prop-types';




const Formulario = ({crearCita}) => {

    //Crear state de citas
    const [cita, actualizaCita] = useState({
        mascota: '',
        propietario: '',
        fecha: '',
        hora: '',
        sintomas: ''
    });
    const [error, actualizarError] = useState(false)

    //Función que se ejecuta cada vez que el usario escribe en un input
    const actualizarState = (e) => {
        actualizaCita({
            ...cita,
            [e.target.name]: e.target.value
        })
    }

    // Extraer valores
    const {mascota, propietario, fecha, hora, sintomas} = cita;


    //Cuando se envía el formulario
    const submitCita = (e) => {
        e.preventDefault();

        //validar
        if(mascota.trim() === '' || propietario.trim() === '' || fecha.trim() === '' 
        || hora.trim() === ''  || sintomas.trim() === ''){
            actualizarError(true);
            return;
        }
        //Eliminar el mensaje previo
        actualizarError(false);


        //Asignar ID
        cita.id = uuidv4();
    

        //Crear cita
        crearCita(cita);

        //Reiniciar el form
        actualizaCita({
            mascota: '',
            propietario: '',
            fecha: '',
            hora: '',
            sintomas: ''
        })

    }

    return ( 
        <>
            <h2>Crear cita</h2>

            {error ? <p className="alerta-error">
                Todos los campos son obligatorios</p>    :null}

            <form
                onSubmit={submitCita}
            >
                <label>Nombre Mascota</label>
                <input 
                    type="text"
                    name="mascota"
                    className="u-full-width"
                    placeholder="Nombre Mascota"
                    onChange={actualizarState}
                    value={mascota}
                />
                <label>Nombre Dueño</label>
                <input 
                    type="text"
                    name="propietario"
                    className="u-full-width"
                    placeholder="Nombre dueño de la mascota"
                    onChange={actualizarState}
                    value={propietario}
                />
                <label>Fecha</label>
                <input 
                    type="date"
                    name="fecha"
                    className="u-full-width"
                    onChange={actualizarState}
                    value={fecha}
                />
                <label>Hora</label>
                <input 
                    type="time"
                    name="hora"
                    className="u-full-width"
                    onChange={actualizarState}
                    value={hora}
                />
                <label>Síntomas</label>
                <textarea 
                    className="u-full-width"
                    name="sintomas"
                    onChange={actualizarState}
                    value={sintomas}
                    
                ></textarea>
                <button
                    type="submit"
                    className="u-full-width button-primary"
                >Agregar Cita</button>
            </form>

        </>
     );
}
 
Formulario.propTypes = {
    crearCita: PropTypes.func.isRequired
}

export default Formulario;