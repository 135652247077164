import React, {useState, useEffect} from 'react';
import Formulario from './components/Formulario';
import Cita from './components/Cita';


function App() {

  //Citas en local storage
  let citasIniciales = JSON.parse(localStorage.getItem('citas'));
  if (!citasIniciales) {
    citasIniciales = [];
  } 

  // Arreglo de citas
  const [citas, guardarCitas] = useState([]);

  // useEffect para realizar ciertas operaciones cuando el state cambia
  useEffect ( () => {
    let citasIniciales = JSON.parse(localStorage.getItem('citas'));
    if(citasIniciales) {
      localStorage.setItem('citas', JSON.stringify(citas))
    } else {
      localStorage.setItem('citas', JSON.stringify([]));
    }
  }, [citas] );
  
  // Función sitas acyuales y agregue nueva
  const crearCita = (cita) => {
    guardarCitas([
      ...citas,
      cita
    ]);
  }

  //Función elimina citas por id
  const eliminarCita = (id) => {
    const nuevasCitas = citas.filter(cita => cita.id !== id);
    guardarCitas(nuevasCitas);
  }


  //Mensaje condicional para la administracion de citas
  const titulo = citas.length === 0 ?'No hay citas'    :'Administra tus citas';




  return (
   <>
      <h1>Administrador de pacientes</h1>
  
  
      <div className="container">
        <div className="row">
          <div className="one-half column">
              <Formulario 
                crearCita={crearCita}
              />
          </div>
          <div className="one-half column">
              <h2>{titulo}</h2>
              {citas.map(cita =>(
                <Cita 
                  key={cita.id}
                  cita={cita}
                  eliminarCita={eliminarCita}

                />
              ))}
          </div>
        </div>

      </div>
   </>
  );
}

export default App;
